<script setup>
const config = useRuntimeConfig();

useHead({
  script: [
    {
      src: config.public.mode.isDev ? "/browser-support-test.js" : "/browser-support.js",
    },
  ],
});

const { colorMode, font } = useTheme();

</script>

<template>
  <div>
    <Html
      lang="nl"
      dir="ltr"
      class="relative"
      :data-theme="colorMode"
      :data-font="font"
    >
      <Body :class="config.public.mode.value === 'dev' ? 'debug-screens' : ''">
        <slot />
        
      </Body>
    </Html>
  </div>
</template>

<style>
.debug-screens::before {
  border-top-right-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
